<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center">
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.excelReport") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input size="mini" :placeholder="$t('message.search')" prefix-icon="el-icon-search"
              v-model="filterForm.search" clearable></el-input>
          </div>
        </div>
        <div class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end">
          <el-button v-can="'roles.create'" size="mini" @click="drawerCreate = true" icon="el-icon-circle-plus-outline">
            {{ $t("message.create") }}
          </el-button>
          <export-excel v-can="'roles.excel'" class="btn excel_btn" :data="list" :fields="excel_fields"
            @fetch="controlExcelData()" worksheet="Роли" name="Роли.xls">
            <el-button size="mini" icon="el-icon-document-delete">
              Excel
            </el-button>
          </export-excel>
          <crm-column-settings :columns="columns" :modelName="'roles'" @c-change="updateColumn"></crm-column-settings>
        </div>
      </div>
      <table class="table table-bordered table-hover" v-loading="loadingData">
        <thead>
          <tr>
            <crm-th :column="columns.id" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.name" :sort="sort" @c-change="updateSort"></crm-th>
            <!-- <crm-th :sort="sort" :column="columns.slug" @c-change="updateSort"></crm-th> -->
            <crm-th :sort="sort" :column="columns.created_at" @c-change="updateSort"></crm-th>
            <crm-th :sort="sort" :column="columns.updated_at" @c-change="updateSort"></crm-th>
            <crm-th :sort="sort" :column="columns.settings" @c-change="updateSort"></crm-th>
          </tr>
          <tr>
            <th v-if="columns.id.show">
              <el-input clearable size="mini" class="id_input" v-model="filterForm.id"
                :placeholder="columns.id.title"></el-input>
            </th>
            <th v-if="columns.name.show">
              <el-input size="mini" v-model="filterForm.name" :placeholder="columns.name.title" clearable>
              </el-input>
            </th>
            <!-- <th v-if="columns.slug.show">
                            <el-input size="mini" v-model="filterForm.slug" :placeholder="columns.slug.title" clearable>
                            </el-input>
                        </th> -->
            <th v-if="columns.created_at.show">
              <el-date-picker :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" v-model="filterForm.created_at"
                :placeholder="columns.created_at.title" size="mini">
              </el-date-picker>
            </th>
            <th v-if="columns.updated_at.show">
              <el-date-picker :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" v-model="filterForm.updated_at"
                :placeholder="columns.updated_at.title" size="mini">
              </el-date-picker>
            </th>
            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>
        <transition-group name="flip-list" tag="tbody">
          <tr v-for="(role, ind) in list" :key="'roles' + ind" class="cursor-pointer">
            <td v-if="columns.id.show">{{ role.id }}</td>
            <td v-if="columns.name.show">{{ role.name }}</td>
            <!-- <td v-if="columns.slug.show">{{ role.slug }}</td> -->
            <td v-if="columns.created_at.show">
              {{ role.created_at }}
            </td>
            <td v-if="columns.updated_at.show">
              {{ role.updated_at }}
            </td>
            <td v-if="columns.settings.show" class="settings-td">
              <crm-setting-dropdown name="roles" :model="role" :actions="actions" @edit="edit"
                @delete="destroyModel"></crm-setting-dropdown>
            </td>
          </tr>
        </transition-group>
      </table>

      <!-- Creating ExcelColumnsList  -->
      <el-drawer class="popups_title" :visible.sync="drawerCreate" @close="reloadIfChanged" size="80%"
        :wrapperClosable="false">
        <CrmCreate :reloadModel="reopenUpdate" @c-close="reloadIfChanged('drawerCreate')"></CrmCreate>
      </el-drawer>
      <!-- Creating Role model  -->
      <el-drawer class="popups_title" :visible.sync="drawerCreate" @close="reloadIfChanged" size="80%"
        :wrapperClosable="false">
        <CrmCreate :reloadModel="reopenUpdate" @c-close="reloadIfChanged('drawerCreate')"></CrmCreate>
      </el-drawer>
      <!-- Creating ExcelColumnsList  -->

      <!-- UpdatingExcelColumnsList  -->
      <el-drawer class="popups_title" :visible.sync="drawerUpdate" direction="rtl" size="80%" :wrapperClosable="false"
        :drawer="drawerUpdate" @open="reopenUpdate = true" @close="reloadIfChanged('reopenUpdate')" @closed="emptyModel">
        <CrmUpdate :reloadModel="reopenUpdate" @open="reopenUpdate = true" @c-close="closeDrawer"></CrmUpdate>
      </el-drawer>
      <!-- Updating Role model  -->
      <el-drawer class="popups_title" :visible.sync="drawerUpdate" direction="rtl" size="80%" :wrapperClosable="false"
        :drawer="drawerUpdate" @open="reopenUpdate = true" @close="reloadIfChanged('reopenUpdate')" @closed="emptyModel">
        <CrmUpdate :reloadModel="reopenUpdate" @open="reopenUpdate = true" @c-close="closeDrawer"></CrmUpdate>
      </el-drawer>
      <!-- Updating ExcelColumnsList  -->
    </div>
  </div>
</template>
<script>
import CrmUpdate from "./update";
import CrmCreate from "./create";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
export default {
  mixins: [list],
  props: {
    reloadModel: { type: Boolean, default: true }
  },
  name: "ExcelColumnsList",
  components: {
    CrmCreate,
    CrmUpdate,
  },
  data() {
    return {
      drawerCreate: false,
      drawerUpdate: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "excelColumns/list",
      columns: "excelColumns/columns",
      filter: "excelColumns/filter",
      sort: "excelColumns/sort",
      parent_permissions: "permissions/parent_permissions",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() { },
  async mounted() {
    this.controlExcelData();

    // if (this.parent_permissions && this.parent_permissions.length === 0)
    //     await this.loadParentPermissions();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      updateSort: "excelColumns/updateSort",
      updateFilter: "excelColumns/updateFilter",
      updateColumn: "excelColumns/updateColumn",
      updateList: "excelColumns/index",
      updatePagination: "excelColumns/updatePagination",
      editModel: "excelColumns/show",
      empty: "excelColumns/empty",
      delete: "excelColumns/destroy",
      refreshData: "excelColumns/refreshData",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    handleCurrentChange(val) {
      this.updatePagination({ key: "page", value: val })
        .then((res) => { })
        .catch((err) => { });
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => { });
    },
    async edit(model) {
      await this.editModel(model.id);
      this.drawerUpdate = true;
    },
    controlExcelData() {
      this.excel_fields = {};
      console.log(this.columns)
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    destroyModel(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
